<template>
    <div>
        <NuevaTransferencia
            :compradecimales="config.compradecimales"
            :almacenid="config.almacenid"
            :monedasimbolo="config.monedasimbolo"
            :userid="config.userid"
            :preciocifradecimal="config.preciocifradecimal" 
            :preciocifraentera="config.preciocifraentera" 
            :precioreglatres="config.precioreglatres" 
            :utilidadporcentaje="config.utilidadporcentaje"
            :simbololiteralplural="config.simbololiteralplural"
            :disabledvencimiento="config.disabledvencimiento"
            :statecodigobarradefault="config.statecodigobarradefault" 
            :sessionuserid="config.userid"
        />
    </div>
</template>
    
<script>
    import NuevaTransferencia from './Modulos/NuevaTransferencia.vue'
    
    export default {
        
        props: [
            'usuario',
            'config'
        ],

        components: {
            NuevaTransferencia
        }

    }
</script>