import { render, staticRenderFns } from "./NuevaTransferencia.vue?vue&type=template&id=90c13204"
import script from "./NuevaTransferencia.vue?vue&type=script&lang=js"
export * from "./NuevaTransferencia.vue?vue&type=script&lang=js"
import style0 from "./NuevaTransferencia.vue?vue&type=style&index=0&id=90c13204&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports