<template>
    <div v-if="$can('desplazamiento.menu.nuevo.traspaso')">
        <a-row type="flex" :gutter="[8,8]">
            <a-col class="flex-card-left" flex="1 1 75%">
                <a-row type="flex" :gutter="[2,2]">
                    <a-col flex="1 0 10%" v-if="$can('desplazamiento.traspaso.por.sucursal.de.origen')">
                        <span class="d-sm-block"><strong>Sucursal Origen:</strong></span>
                        <a-select name="ElegirSucursalGenerado" size="large" :value="transferencia.almacen_origen_id" @change="getListProductoAlmacen"  style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 0 10%">
                        <span class="d-sm-block"><strong>Sucursal Destino:</strong></span>
                        <a-select name="ElegirSucursalGenerado" size="large" :value="transferencia.almacen_destino_id" @change="validationAlmacen"  style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 0 10%" v-if="$can('desplazamiento.busqueda.de.producto.en.traspasos')">
                        <span class="d-sm-block"><strong>Buscar:</strong></span><br>
                        <a-button size="large" type="primary" @click="ViewModalIframeProductos()"><a-icon type="eye" /> Productos</a-button>
                    </a-col>

                    <a-col flex="1 0 10%">
                        <span class="d-sm-block"><strong>Fecha:</strong></span>
                        <a-date-picker size="large" block :value="getDateFormat(transferencia.fecharegistro)" @change="onDateTranspaso" :locale="locale" style="width: 100%"/>
                    </a-col>

                    <a-col flex="1 0 35%">
                        <span class="d-sm-block"><strong>Observaciones:</strong></span>
                        <a-textarea 
                            v-model="transferencia.observaciones" 
                            placeholder="Observaciones de Transpaso" 
                            >
                        </a-textarea>
                    </a-col>
                </a-row>

                <a-row type="flex" :gutter="[8,8]">
                    <a-col flex="1 0 30%">
                        <label style="font-weight:700; font-size:12px;">CODIGO BARRAS: <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F1)</span></label>
                        <a-input 
                            size="large"
                            v-model="valueCodigoBarras"
                            id="CodigoBarrasOnfocus"
                            @keypress.enter="validateCodigoBarras($event)"
                        />
                    </a-col>

                    <a-col flex="1 0 70%">
                        <label style="font-weight:700; font-size:12px;">SELECCIONA LOS PRODUCTOS PARA EL TRASPASO: <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F2)</span> &nbsp;&nbsp; <span style="font-weight:700; font-size:11px; color: rgb(32, 80, 129);">Cerrar </span><span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(ESC)</span></label>
                        <a-select
                            size="large"
                            show-search
                            :value="valueProductoScroll"
                            :default-active-first-option="false"
                            :show-arrow="true"
                            :filter-option="false"
                            :not-found-content="spinnerScroll ? undefined : null"
                            @search="handleBusquedaListaProductos"
                            @change="handleChangeProductos"
                            placeholder="Buscar Productos por Codigo, Nombre, Descripción, Medida, Marca, Unidad."
                            style="width: 100%"
                            id="productoSelect"
                            >
                            <a-spin v-if="spinnerScroll" slot="notFoundContent" size="large">
                                <div class="spin-content">
                                    <i class="fas fa-search"></i> Obteniedo los Resultados ...
                                </div>
                            </a-spin>
                            <template v-if="ismarcaizquierdalistatransferencia == 'ACTIVADO'">
                                <a-select-option v-for="(option, i) in lista_producto_scroll" :key="i" :value="option.producto_id">
                                    {{ option.codigo }} {{ option.marca }} {{ option.nombre }} {{ option.medida }} {{ option.unidad }}
                                </a-select-option>
                            </template>
                            <template v-if="ismarcaizquierdalistatransferencia == 'DESACTIVADO'">
                                <a-select-option v-for="(option, i) in lista_producto_scroll" :key="i" :value="option.producto_id">
                                    {{ option.codigo }} {{ option.nombre }} {{ option.medida }} {{ option.unidad }} {{ option.marca }}
                                </a-select-option>
                            </template>
                        </a-select>
                    </a-col>
                </a-row>

                <br>

                <div class="box-body table-responsive no-padding">
                            <table class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>STOCK</th>
                                        <th>ARTICULO</th>
                                        <th><center>CANTIDAD</center></th>
                                        <th><center>PRECIO COMPRA</center></th>
                                        <th><center>SUBTOTAL</center></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody id="listaproducto">
                                    <tr v-for="(item, i) in tablaproducto" :key="i">
                                        <td>{{ item.saldoFisico }}</td>
                                        <td>{{ item.nombre }} {{ item.medida }}</td>
                                        <td bgcolor="#eeffd0" style="text-align: center;" @click="newCantidad = (i+'_'+item.producto_id);valueCantidad=item.cantidad">
                                            <label v-show="newCantidad != (i+'_'+item.producto_id)">{{ item.cantidad }}</label>
                                            <input style="width: 50px" v-if="newCantidad == (i+'_'+item.producto_id)" v-model="valueCantidad" v-on:blur="editCantidad(i,item.producto_id); $emit('update')" @keyup.enter="editCantidad(i,item.producto_id); $emit('update')" v-on:keyup="validacionCantidad()">
                                        </td>
                                        <td bgcolor="#ddf7ff" style="font-weight: 700;">
                                        {{ item.precioCompra }}
                                        </td>
                                        <td>
                                            {{ item.subtotal }}
                                        </td>
                                        <td>
                                            <center>
                                                <a href="#" @click.prevent="deleteItem(i)">
                                                    <a-icon type="close-circle" :style="{ fontSize: '20px', color: '#EE0808' }" />
                                                </a>
                                            </center>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                </div>
            </a-col>

            <a-col class="flex-card-right" flex="1 0 25%">
                <a-row type="flex" :gutter="[8,8]">
                    <a-col flex="1 0 100%">
                        <div style="height: 35%; background-color:#000000; padding-top: 1px; overflow-x: scroll;">
                            <center><label style="color:#fff;">Precio Total</label></center>
                            <center><font size="7" color="#0ed104"><strong><p>{{ montoTotal }}</p></strong></font></center>
                        </div>
                        <div>
                            <h4 class="titulo-venta-color"><center>NRO DE TRASPASO</center></h4>
                            <h2 class="codigodeventa">{{ transferencia.codigo }}</h2>
                        </div>
                        <div>
                            <button
                                type="button" 
                                class="btn btn-block btn-lg" 
                                style="background-color:var(--element);" 
                                @click.once="realizarTransferencia()" 
                                :key="buttonKeyGuardar"
                                :disabled="disabledButtonEnvio == false"
                                >
                                <font size=3><center><strong style="color:white;">REALIZAR TRASPASO</strong></center></font>
                            </button>
                        </div>
                        <br>
                        <div>
                            <a-button 
                                type="danger" 
                                block
                                @click.once="eliminarListaProductos()" 
                                :key="buttonKey">
                                Vaciar lista de items
                            </a-button>
                        </div>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>

        <!--PRODUCTO PROVEEDOR-->
        <a-modal
                v-model="showModalIframeProducto"
                title="BUSQUEDA DE PRODUCTOS"
                :closable="true"
                :destroyOnClose="true"
                :maskClosable="false"
                :dialog-style="{ top: '5px' }"
                :width="1300"
                :zIndex="1049"
                >

                <div class="row mb-12">
                    <ModuloProductoProveedor 
                        :utilidadporcentaje="utilidadporcentaje"
                        :statecodigobarradefault="statecodigobarradefault" 
                        :disabledvencimiento="disabledvencimiento" 
                        :preciocifradecimal="preciocifradecimal" 
                        :preciocifraentera="preciocifraentera" 
                        :precioreglatres="precioreglatres"  
                        :sessionuserid="userid"
                        :monedasimbolo="monedasimbolo"
                        :almacenid="almacenid"			
                    /> 
                </div>

                <template slot="footer">
                    <a-button key="back" @click="cerrarShowModalIframeProducto()">
                        CERRAR MODAL
                    </a-button>
                </template>
        </a-modal>
        <!--PRODUCTO PROVEEDOR-->

        <a-modal
			v-model="isTraspaso"
			title="Traspaso Realizado"
            :maskClosable="false"
            :footer="false"
			>
			<a-alert 
				message="Debe leer el mensaje:"
				type="success"
				banner
				closable
				>
				<p slot="description">
					Puede <strong>aprobar la solicitud de traspaso</strong> que queda pendiente de aprobación. <br><br>
                    Después de la revisión, el <strong>traspaso puede ser aprobado o rechazado</strong>. Si es aprobado, se <strong>actualizan los inventarios de ambas sucursales</strong>: se descuenta la cantidad del inventario de la sucursal de origen y se suma al inventario de la sucursal de destino.
				</p>
			</a-alert>
            <br>
            <center>
                <a-button
                    type="primary"
                    size="large"
                    @click="openMenu('consultatranspaso')" 
                    > 
                    <a-icon type="exception" :style="{ fontSize: '20px', fontWeight: '700' }"/> Confirmar Traspaso Ahora
                </a-button>
            </center>
		</a-modal>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1050;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Realizando la Transferencia..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    </div>
</template>

<script>
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import { EuropioCode } from '@/utils/europiocode'
	import axios from '@/config/axios/axios'
	import Swal from 'sweetalert2'
	import numeral from 'numeral'
    import router from '@/config/router'
    import { mapGetters, mapActions } from 'vuex'

    import { 
		URI_ALMACEN_GET_LISTAR,
        URI_PRODUCTO_LISTA_MASIVA,
        URI_TRANSFERENCIA_DATO_AGREGAR,
        URI_PRODUCTO_POST_CODIGO_TRASPASO,
        URI_PRODUCTO_POST_TRASPASO,
        URI_PRODUCTO_BUSCAR_SCROLL,
        URI_TRANSFERENCIA_GUARDAR
	} from '@/utils/constants'

    import ModuloProductoProveedor from './ModuloProductoProveedor.vue'

    export default {

        props: [
            'compradecimales',
            'almacenid',
            'monedasimbolo',
            'userid',
            'preciocifradecimal', 
            'preciocifraentera', 
            'precioreglatres',
            'utilidadporcentaje',
            'simbololiteralplural',
            'disabledvencimiento',
            'statecodigobarradefault',
            'sessionuserid'
        ],

        components: {
			ModuloProductoProveedor
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
                utils,
                lista_almacenes: [],
                listproducto: [],
                data_producto: [],
                tablaproducto: [],
                newCantidad: '',
                valueCantidad: '',
                transferencia: {
                    almacen_origen_id: this.almacenid.toString(),
                    almacen_destino_id: 'Seleccionar',
                    fecharegistro: '',
					observaciones: '',
					estado: '',
					codigo: '',
					usuario: '',
					fechaActual: '',
					horaActual: ''
                },
                ismarcaizquierdalistatransferencia: 'ACTIVADO',
                valueCodigoBarras: '',
                productoSeleccionado: '',
				tablaVentanaNuevaID: 'tablaproductotransferencia' + this.nuevaventanaventaid,
				disabledButtonEnvio: false,
				buttonKey: 10,
                buttonKeyGuardar: 100,
				contador: 0,
				clicked: false,
				spinnerloading: false,
                valueProductoScroll: undefined,
                lista_producto_scroll: [],
                spinnerScroll: false,
                showModalIframeProducto: false,
                isTraspaso: false
            }
        }, 

        mounted () {
            this.comandkeys()
            this.getAlmacenes()
            this.getLocalStorage()
			this.transferencia.fecharegistro = moment().format('YYYY-MM-DD')
            this.getProdutoBusquedaMasiva()
        },

        methods: {
            openMenu (name) {
				router.push({ name: name })
			},

            comandkeys () {
				var self = this

				var ENTER_KEY_CLOSE = 27
				var ENTER_KEY_SUB_CLOSE = 27
				var ENTER_KEY_CONFIRMAR = 13

				var ENTER_KEY_CODIGO_BARRA = 112
				var ENTER_KEY_LISTA = 113
				var ENTER_KEY_IMPORTE = 114
				var ENTER_KEY_CLIENTE = 115
				var ENTER_KEY_TIPO_SALIDA = 116
				var ENTER_KEY_AGREGAR_CLIENTE = 117
				var ENTER_KEY_AGREGAR_SOLICITANTE = 118
				var ENTER_KEY_GUARDAR_CLIENTE = 119
				var ENTER_KEY_GUARDAR_SALIDA = 120
				var ENTER_KEY_MAYUS = 16
				var ENTER_KEY_O = 79
				var ENTER_KEY_J = 74
				var ENTER_KEY_CTRL = 17
				var ENTER_KEY_PLUS = 61

				document.addEventListener('keydown', function (e) {
                    const code = e.keyCode || e.which
                    const habilitarControlCodigoBarras = self.config.habilitarcontrolcodigobarras === 'ACTIVADO'
                    const codesToPrevent = [74, 61, 17, 27, 112, 113, 114, 115, 116, 117, 118, 119, 120]

                    if ((habilitarControlCodigoBarras && codesToPrevent.includes(code)) || (!habilitarControlCodigoBarras && codesToPrevent.slice(1).includes(code))) {
                        e.preventDefault()
                        
                        switch (code) {
                            case ENTER_KEY_TIPO_SALIDA:
                                console.log('F5')
                                break
                            case ENTER_KEY_J:
                            case ENTER_KEY_PLUS:
                            case ENTER_KEY_CTRL:
                            case ENTER_KEY_CODIGO_BARRA:
                                document.getElementById('CodigoBarrasOnfocus').focus()
                                break
                            case ENTER_KEY_CLOSE:
                                document.getElementById('CodigoBarrasOnfocus').focus()
                                document.getElementById('CerrarModalCliente').click()
                                break
                            case ENTER_KEY_SUB_CLOSE:
                                document.querySelector('.swal2-cancel').click()
                                break
                            case ENTER_KEY_CONFIRMAR:
                                document.querySelector('.swal2-confirm').click()
                                document.getElementById('CodigoBarrasOnfocus').dispatchEvent(new KeyboardEvent('keypress'))
                                break
                            case ENTER_KEY_GUARDAR_SALIDA:
                                document.getElementById('EnviarInformacion').click()
                                break
                            case ENTER_KEY_LISTA:
                                document.getElementById('productoSelect').click()
                                break
                            case ENTER_KEY_IMPORTE:
                                document.getElementById('ImportePagar').focus()
                                document.getElementById('ImportePagar').select()
                                break
                            case ENTER_KEY_CLIENTE:
                                break
                            case ENTER_KEY_AGREGAR_SOLICITANTE:
                                document.getElementById('InputSolicitante').focus()
                                break
                            case ENTER_KEY_AGREGAR_CLIENTE:
                                document.getElementById('AgregarCliente').click()
                                break
                            case ENTER_KEY_GUARDAR_CLIENTE:
                                document.getElementById('GuardarCliente').click()
                                break
                            default:
                                break
                        }
                    }
                })
			},

            numberFormat (number) {
				return utils.formatMoney(number, this.compradecimales, '.', '')
			},

            getDateFormat (date) {
				const d = moment(date)
				return date && d.isValid() ? d : ''
			},

            ViewModalIframeProductos () {
                setTimeout(function () { 
                    this.showModalIframeProducto = true
                }.bind(this), 300)
			},

            cerrarShowModalIframeProducto () {
				this.getProdutoBusquedaMasiva()
				this.showModalIframeProducto = false
			},

            getLocalStorage () {
                const datosDB = JSON.parse(localStorage.getItem(this.tablaVentanaNuevaID))
                if (datosDB === null) {
                    this.tablaproducto = []
                    return this.tablaproducto
                } else {
                    this.tablaproducto = datosDB
                    return this.tablaproducto
                }
            },

            saveLocalStorage () {
                localStorage.setItem(this.tablaVentanaNuevaID, JSON.stringify(this.tablaproducto))
            },

            removeItemLocalStorage () {
                this.tablaproducto = []
                localStorage.removeItem(this.tablaVentanaNuevaID)
            },

            urlLocationVentanaVenta () {
                if (this.nuevaventanaventaid === 1) {
                    // window.location.assign('/libreria/transferencia/agregar')
                } else {
                    const idventana = parseInt(this.nuevaventanaventaid) - 1
                    // window.location.assign(`/libreria/transferencia/agregar/${idventana}`)
                }
            },

            getAlmacenes () {
                axios.get(URI_ALMACEN_GET_LISTAR)
                .then(response => {
                    this.lista_almacenes = response.data
                    this.transferencia.almacen_origen_id = this.almacenid.toString()

                    this.listproducto = []
					this.productoSeleccionado = 0
					this.getDatosTranferencia(this.transferencia.almacen_origen_id)
                })
                .catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
				})
            },

            getProdutoBusquedaMasiva () {
				const formData = new FormData()
				formData.append('almacenID', parseInt(this.transferencia.almacen_origen_id))

				axios.post(URI_PRODUCTO_LISTA_MASIVA, formData)
				.then(response => {
					if (response.status === 200) {
						this.lista_producto_scroll = response.data
					}
				})
				.catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
			},

            onDateTranspaso (date, dateString) {
                this.transferencia.fecharegistro = dateString
            },
			
			getDatosTranferencia (almacenID) {
                const formData = new FormData()
				formData.append('almacenID', almacenID)

                axios.post(URI_TRANSFERENCIA_DATO_AGREGAR, formData)
                .then(response => {
                    this.transferencia.codigo = response.data.codigo
                    this.transferencia.usuario = response.data.usuario
                    this.transferencia.fechaActual = response.data.fechaActual
                    this.transferencia.horaActual = response.data.horaActual
				})
                .catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
				})
            },

            getListProductoAlmacen (value) {
                this.transferencia.almacen_origen_id = value
				if (this.transferencia.almacen_origen_id === this.transferencia.almacen_destino_id) {
					this.disabledButtonEnvio = false
					Swal.fire('Atencion!', 'Debe elegir un Sucursal Destino direfente de Sucursal Origen...', 'error')
				} else {
					this.disabledButtonEnvio = true
					
					this.listproducto = []
					this.productoSeleccionado = 0
					this.getDatosTranferencia(this.transferencia.almacen_origen_id)
					this.getProdutoBusquedaMasiva()
				}
            },

            validateCodigoBarras ($event) {
                if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode)) || $event.keyCode === 13) {
                    if ($event.keyCode === 13) {
                        axios.post(URI_PRODUCTO_POST_CODIGO_TRASPASO, {
                            producto_code: this.valueCodigoBarras, 
                            almacen_id: this.transferencia.almacen_origen_id
                        })
                        .then(response => {
                            if (response.data.code === 404) {
                                Swal.fire('Atención!', 'Por favor agregue Precios de Ventas al Producto según la Sucursal donde pertenezca...', 'error')
                            } else if (this.tablaproducto.length === 0) {
                                this.tablaproducto.push(response.data)
                                this.data_producto = response.data
                                this.saveLocalStorage()
                            } else {
                                let exists = false
                                for (const element of this.tablaproducto) {
                                    if (parseInt(element.producto_id) === parseInt(response.data.producto_id)) {
                                        exists = true
                                        Swal.fire('Atención!', `El Producto ${response.data.nombre} ${response.data.medida} ya fue elegido...`, 'error')
                                        break
                                    }
                                }
                                if (!exists) {
                                    this.tablaproducto.push(response.data)
                                    this.data_producto = response.data
                                    this.saveLocalStorage()
                                }
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                        this.valueCodigoBarras = ''
                        return false
                    }
                } else {
                    $event.preventDefault()
                }
            },

            agregarProducto () {
                const data = this.productoSeleccionado
                if (data === 0) {
                    Swal.fire('Atencion!', 'Elija un Producto de la Lista...', 'error')
                } else {
                    axios.post(URI_PRODUCTO_POST_TRASPASO, {
                        producto_id: data,
                        almacen_id: this.transferencia.almacen_origen_id
                    })
                    .then(response => {
                        if (response.data.code === 404) {
                            Swal.fire('Atención!', 'Por favor agregue Precios de Ventas al Producto según la Sucursal donde pertenezca...', 'error')
                        } else {
                            if (this.tablaproducto.length === 0) {
                                this.tablaproducto.push(response.data)
                                this.data_producto = response.data
                                // LOCAL STORAGE
                                this.saveLocalStorage()
                            } else {
                                let exists = false
                                for (const element of this.tablaproducto) {
                                    if (parseInt(element.producto_id) === parseInt(response.data.producto_id)) {
                                        exists = true
                                        Swal.fire('Atención!', `El Producto ${response.data.nombre} ${response.data.medida} ya fue elegido...`, 'error')
                                        break
                                    }
                                }
                                if (!exists) {
                                    this.tablaproducto.push(response.data)
                                    this.data_producto = response.data
                                    // LOCAL STORAGE
                                    this.saveLocalStorage()
                                }
                            }
                        }
                    })
                    .catch(error => {
						utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
					})
                }
			},
			
            handleChangeProductos (value) {
				this.productoSeleccionado = parseInt(value) /** PRODUCTO ID */
				this.spinnerloading = true
				this.agregarProducto()
				this.spinnerloading = false

				var self = this
				setTimeout(function () {
					const producto = self.data_producto
					if (Object.entries(producto).length > 1) {
						if (self.ismarcaizquierdalistaventa === 'ACTIVADO') {
							const cadena = `${producto.codigo} ${producto.marca} ${producto.nombre} ${producto.medida} ${producto.procedencia}`
							self.valueProductoScroll = cadena
						} else if (self.ismarcaizquierdalistaventa === 'DESACTIVADO') {
							const cadena = `${producto.codigo} ${producto.nombre} ${producto.medida} ${producto.procedencia} ${producto.marca}`
							self.valueProductoScroll = cadena
						}
					} else if (Object.entries(producto).length === 2) {
						self.valueProductoScroll = 'Selecciona un producto...'
					}
				}, 500)
			},

            handleBusquedaListaProductos (value) {
				if (value !== '') {
					if (value.length > 2) {
						const $this = this
						const ecode = new EuropioCode()
						const formData = new FormData()
						formData.append('almacenID', parseInt(this.transferencia.almacen_origen_id))
						formData.append('keyword', ecode.encode(value))

						this.spinnerScroll = true

						axios.post(URI_PRODUCTO_BUSCAR_SCROLL, formData)
						.then(response => {
							if (response.status === 200) {
								this.lista_producto_scroll = response.data
								this.spinnerScroll = false
							}
						})
						.catch(error => {
							utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
						})
					}
				}
			},

			deleteItem (i, j) {
                this.tablaproducto.splice(i, 1)
                this.saveLocalStorage()
            },

            validacionCantidad () {
                this.valueCantidad = (this.valueCantidad + '').replace(/[^0-9]/g, '')
			},
			
			editCantidad (i, j) {
                this.newCantidad = ''
				// PRODUCTO - CODIGO : 1
                if (this.valueCantidad === '' || this.valueCantidad === 0) {
                    Swal.fire('Atencion!', 'Introduzca una Cantidad...', 'error')
                } else if (parseInt(this.valueCantidad) < parseInt(this.tablaproducto[i].cantidad)) {
                    Swal.fire('Atencion!', 'La cantidad ingresada debe ser mayor a la Cantidad Actual...', 'error') 
                } else if (parseInt(this.valueCantidad) > parseInt(this.tablaproducto[i].saldoFisico)) {
                    Swal.fire('Atencion!', 'La cantidad ingresada es mayor al stock del producto vuelva a ingresar una cantidad menor...', 'error')
                } else {
                    this.tablaproducto[i].cantidad = 0
                    this.tablaproducto[i].cantidad = parseInt(this.valueCantidad)
                    const subtotal = parseInt(this.valueCantidad) * parseFloat(this.tablaproducto[i].precioCompra)
					this.tablaproducto[i].subtotal = this.numberFormat(subtotal)
			        // LOCAL STORAGE
                    this.saveLocalStorage()
                }
			},
			
			eliminarListaProductos () {
                this.buttonKey++
                this.removeItemLocalStorage()
			},
			
			validationAlmacen (value) {
                this.transferencia.almacen_destino_id = value
				if (this.transferencia.almacen_origen_id === this.transferencia.almacen_destino_id) {
					this.disabledButtonEnvio = false
					Swal.fire('Atencion!', 'Debe elegir un Sucursal Destino direfente de Sucursal Origen...', 'error')
				} else if (this.transferencia.almacen_origen_id === '') {
					this.disabledButtonEnvio = false
                    utils.openNotificationWithIcon('info', 'Mensaje : ', 'Elija un Sucursal Origen por favor...', 'topRight')
				} else {
					this.disabledButtonEnvio = true
				}
			},

			realizarTransferencia () {
                if (this.tablaproducto === '') {
                    Swal.fire('Atencion!', 'Por favor seleccione un producto para la transpaso y vuelva a intentar...', 'error')
                } else {
                            Swal.fire({
                                title: 'Desea realizar el registro del Transpaso?',
                                text: 'Tome en cuenta que debera registrar el transpaso y luego validar su operacion en el sistema.',
                                type: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Si, Proceder Ahora',
                                cancelButtonClass: 'btn btn-danger',
                                confirmButtonClass: 'btn btn-success',
                                cancelButtonText: 'No, Proceder!'
                            })
                            .then((result) => {
                                if (result.value) {
                                    this.spinnerloading = true
									const ecode = new EuropioCode()
									const formData = new FormData()
										
									formData.append('almacen_origen_id', (this.transferencia.almacen_origen_id === '') ? '' : parseInt(this.transferencia.almacen_origen_id))
									formData.append('almacen_destino_id', (this.transferencia.almacen_destino_id === '') ? '' : parseInt(this.transferencia.almacen_destino_id))
									formData.append('fechaRegistro', (this.transferencia.fecharegistro === '') ? '' : ecode.encode(this.transferencia.fecharegistro))
									formData.append('observaciones', (this.transferencia.observaciones === '') ? '' : ecode.encode(this.transferencia.observaciones))
									formData.append('montototal', (this.originalSubTotal === '') ? '' : ecode.encode(this.originalSubTotal.toString()))
									formData.append('productos', ecode.encode(JSON.stringify(this.tablaproducto)))

                                    axios.post(URI_TRANSFERENCIA_GUARDAR, formData)
                                    .then(response => {
                                        if (response.status === 201) {
											this.clicked = true
                                            this.spinnerloading = false
                                            this.removeItemLocalStorage()
                                            this.tablaproducto = []
                                            utils.openNotificationWithIcon('info', 'Mensaje : ', 'Haz realizado con éxito la operación...', 'topRight')
                                        
                                            const traspasoID = parseInt(response.data.transferenciaID)
                                            if (traspasoID) {
                                                if (response.data.imprimirTranferenciaTermica === 'ACTIVADO') {
                                                    utils.openRecibo(`/reporte/traspaso/termica/${traspasoID}`)
                                                } else {
                                                    utils.openRecibo(`/reporte/traspaso/reporte/carta/${traspasoID}`)
                                                }
                                            }

                                            this.isTraspaso = true
                                        }
                                    })
                                    .catch(error => {
                                        utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                                    })
                                } else if (result.dismiss === Swal.DismissReason.cancel) {
                                    Swal.fire(
                                        'No Realizado',
                                        'No has realizado ninguno :(',
                                        'error'
                                    )
                                    this.buttonKey++
                                }
                            })
                }

                this.buttonKeyGuardar++
            }
        },

        computed: {
            ...mapGetters('configuracion', ['config']),

			subTotal () {
                var sum = 0
                this.tablaproducto.forEach(e => {
                    sum += parseFloat(e.subtotal)
                })
                return this.numberFormat(sum)
            },

            originalSubTotal () {
                var sum = 0
                this.tablaproducto.forEach(e => {
                    sum += parseFloat(e.subtotal)
                })
                return sum
            },

            montoTotal () {
                return this.subTotal
            },

            // eslint-disable-next-line vue/return-in-computed-property
            calculoCambio () {
                var MontoTotal = numeral(this.montoTotal)
                var ImportePagar = numeral(this.importePagar)
                var MontoTotalDescuento = numeral(this.montoDescuentoTotal)
                if (MontoTotalDescuento.value() === 0) {
                    if (MontoTotal.value() === 0) {
                        console.log('Esta Vacio')
                    } else if (ImportePagar.value() < MontoTotal.value()) {
                        return '0.00'
                    } else if (ImportePagar.value() >= MontoTotal.value()) {
                        var resultado = (ImportePagar.value()) - (MontoTotal.value())
                        return this.numberFormat(resultado)
                    }
                } else {
                    if (MontoTotalDescuento.value() === 0) {
                        console.log('Esta Vacio')
                    } else if (ImportePagar.value() < MontoTotalDescuento.value()) {
                        return '0.00'
                    } else if (ImportePagar.value() >= MontoTotalDescuento.value()) {
                        const resultado = (ImportePagar.value()) - (MontoTotalDescuento.value())
                        return this.numberFormat(resultado)
                    }
                }
            },

            TotalDescuentoVenta () {
                var sum = 0
                this.tablaproducto.forEach(e => {
                    sum += parseFloat(e.descuentoVenta)
                })
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.descuentoVenta = this.numberFormat(sum)
                var data = parseFloat(this.originalSubTotal) - parseFloat(this.descuentoVenta)
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.montoDescuentoTotal = this.numberFormat(data)
                return this.numberFormat(sum)
			}

        }
    }
</script>

<style>
    .codigodeventa {
        font-size:18px; 
        text-align:center; 
        background-color:#f3f3f3; 
        color: var(--element); 
        font-weight:700; 
        border-style: solid; 
        border-width: 1px;
        border-color: var(--element); 
        padding-top: 10px; 
        padding-bottom: 10px;
    }

    .border-importe {
        border-width: 1px;
    }
    .flex-card-left { max-width: 76%; }
    .flex-card-right { max-width: 24%; }
    .flex-card-codigo { max-width: 25%; }
    .flex-card-producto { max-width: 75%; }
    .flex-card-tabla { max-width: 100%; }

    @media screen and (min-width: 240px) and (max-width: 320px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 320px) and (max-width: 430px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 540px) and (max-width: 540px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 768px) and (max-width: 768px)  {
        .flex-card-left { max-width: 75%; }
        .flex-card-right { max-width: 25%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
    }
</style>